import { useState } from 'react'
import { ethers } from 'ethers'
import myInstance from '@/configs/ABIs/WaverIDiamond.json'
import myInstanceSepolia from '@/configs/ABIs/WaverIDiamondSepolia.json'
import myInstancev1 from '@/configs/ABIs/WaverIDiamondv1.json'
import { fetchMainBalanceAlchemy } from '@/utils/getTokenBalances'
import { CONTRACTS } from '@/configs/contracts'
import { ZERO_ADDR_ETH } from '@/configs'
import * as amplitude from '@amplitude/analytics-browser'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFamilyStats,
  setWaveInstance,
  setWaveInstanceEvents,
  setMarryDate,
  setMarryStatus,
  setFamilyMembers,
  setFamilyMembersCount,
  setDivideShare,
  setMinimumDeadline,
  setPolicyDays,
  setCheckBalances,
  setProposer,
  setProposed,
  setThreshold,
  setStake,
  setConnectedApps,
  setFamilyDao,
} from '@/store/features/contract/reducer'
import { setBalanceETH, setWalletBalance, setFamilyAccounts, setLovBalance } from '@/store/features/wallet/reducer'

import {
  setExchangeRate,
  setSaleCap,
  setNFTPrice,
  setClaimertimer,
  setPromoAmount,
  setClaimPolicyDays,
} from '@/store/features/mainContract/reducer'
import { IRootState } from '@/store'
import { MARRY_STATUS_NONE } from '@/configs/statuses'
import retry from 'async-retry'

const useFamilyContract = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { Provider, Signer, SignerEvents, currentAccount, currentNetwork } = useSelector(
    (state: IRootState) => state.wallet,
  )
  const { demo } = useSelector((state: IRootState) => state.contract)
  const { currency, marriageContract } = useSelector((state: IRootState) => state.mainContract)
  const dispatch = useDispatch()

  const identifyObj = new amplitude.Identify()
  amplitude.identify(identifyObj)

  const transformCharacterData = (characterData: any) => {
    return {
      id: characterData.id.toNumber(),
      ProposalStatus: characterData.ProposalStatus,
      marriageContract: characterData.marriageContract,
      hasRole: characterData.hasRole,
      name: Buffer.from(ethers.utils.arrayify(characterData.name)).toString(),
    }
  }

  const fetchAvailableAssets = async () => {
    if (currentAccount) {
      const AlchemyBalance = await fetchMainBalanceAlchemy(currentAccount, currency, currentNetwork, Provider)
      dispatch(setWalletBalance(AlchemyBalance))
    }
  }

  const fetchFamilyMembers = async (waveInstance: any) => {
    await retry(
      async () => {
        const txn3 = await marriageContract.getFamilyMembers(waveInstance.address)
        dispatch(setFamilyMembers(txn3))
      },
      {
        retries: 5, // Number of retries before giving up
        factor: 2, // Exponential factor
        minTimeout: 1000, // Minimum wait time before retrying
        maxTimeout: 60000, // Maximum wait time before retrying
        randomize: true, // Randomize the wait time
      },
    )
  }

  const fetchMarryStatus = async (waveInstance: any) => {
    const result = await retry(
      async () => {
        const txn = await waveInstance.getPolicies()
        const marryDate = txn.marryDate.toNumber()
        dispatch(setPolicyDays(txn.policyDays.toNumber()))
        dispatch(setMarryDate(marryDate))
        dispatch(setDivideShare(txn.divideShare.toNumber()))
        dispatch(setMinimumDeadline(txn.setDeadline.toNumber()))

        dispatch(setProposer(txn.proposer))
        dispatch(setProposed(txn.proposed))
        dispatch(setThreshold(txn.threshold.toNumber()))

        const balance = await Provider.getBalance(waveInstance.address)
        const balanceInEth = ethers.utils.formatEther(balance)
        dispatch(setStake(balanceInEth))

        const txn2 = await waveInstance.getMarriageStatus()

        dispatch(setMarryStatus(txn2))
        const txn3 = await waveInstance.getFamilyMembersNumber()

        dispatch(setFamilyMembersCount(txn3.toNumber()))
        const txn8 = await waveInstance.getAllConnectedApps()
        dispatch(setConnectedApps(txn8))

        const familyDAO = txn8?.filter((e) => e.includes(CONTRACTS[currentNetwork].familyDao)).length > 0
        dispatch(setFamilyDao(familyDAO))

        if (!demo) {
          identifyObj.setOnce('partnership_share', txn.divideShare.toNumber())
          identifyObj.setOnce('policy_days', txn.policyDays.toNumber())
          identifyObj.setOnce('minimum_deadline', txn.setDeadline.toNumber())
          identifyObj.setOnce('family_count', txn3.toNumber())
          if (marryDate > 0) {
            identifyObj.setOnce('marry_date', marryDate)
          }

          amplitude.identify(identifyObj)
        }

        setIsLoaded(true)
      },
      {
        retries: 5, // Number of retries before giving up
        factor: 2, // Exponential factor
        minTimeout: 1000, // Minimum wait time before retrying
        maxTimeout: 60000, // Maximum wait time before retrying
        randomize: true, // Randomize the wait time
      },
    )
  }

  const getEthBalance = async () => {
    const result = await retry(async () => {
      Signer.getBalance('latest').then(
        (balance) => {
          const balanceInEth = ethers.utils.formatEther(balance)
          dispatch(setBalanceETH(balanceInEth))
        },
        {
          retries: 5, // Number of retries before giving up
          factor: 2, // Exponential factor
          minTimeout: 1000, // Minimum wait time before retrying
          maxTimeout: 60000, // Maximum wait time before retrying
          randomize: true, // Randomize the wait time
        },
      )
    })
  }

  const fetchFamilyContract = async () => {
    const txn = await marriageContract.checkMarriageStatus(1)
    const accounts = txn.map((contract) => ({ ...contract, network: currentNetwork }))
    dispatch(setFamilyAccounts(accounts))
    let address: string
    if (txn?.length > 0) {
      const key = `lastVaultId${CONTRACTS[currentNetwork].ContractAddress}`
      const myItem = window.localStorage.getItem(key)
      if (myItem !== null) {
        address = myItem
      } else {
        address = txn?.[0].marriageContract
      }
    }

    const contract = txn?.find((item) => item?.marriageContract === address)

    if (contract?.id?.toNumber() > 0) {
      const familyStats = transformCharacterData(contract)
      if (!demo) {
        identifyObj.setOnce('family_contract', familyStats.marriageContract)
        identifyObj.setOnce('family_contract_id', familyStats.id)
        identifyObj.setOnce('family_status', familyStats.ProposalStatus)
        identifyObj.setOnce('hasRole', familyStats.hasRole)
        amplitude.identify(identifyObj)
      }
      dispatch(setFamilyStats(familyStats))
      if (familyStats?.marriageContract !== ZERO_ADDR_ETH) {
        const signer = Provider?.getUncheckedSigner()
        let instanceABI = myInstance.abi
        if (currentNetwork === '0xaa36a7' || currentNetwork === '0x1') {
          instanceABI = myInstanceSepolia.abi
        }

        const waveInstance = new ethers.Contract(
          familyStats?.marriageContract,
          demo ? myInstancev1.abi : instanceABI,
          signer,
        )
        dispatch(setWaveInstance(waveInstance))
        const waveInstanceEvent = new ethers.Contract(
          familyStats?.marriageContract,
          demo ? myInstancev1.abi : instanceABI,
          SignerEvents,
        )
        dispatch(setWaveInstanceEvents(waveInstanceEvent))
        fetchMarryStatus(waveInstance)
        fetchFamilyMembers(waveInstance)
        fetchConstants()
        fetchLovBalanceShort()
        dispatch(setCheckBalances(false))
      }
      if (familyStats.ProposalStatus !== 0) {
        fetchLovBalance(familyStats)
      }
    } else {
      setIsLoaded(true)
      dispatch(setMarryStatus(MARRY_STATUS_NONE))
    }
  }

  const fetchConstants = async () => {
    const result = await retry(
      async () => {
        const txn3 = await marriageContract.saleCap()
        dispatch(setSaleCap(ethers.utils.formatEther(txn3)))
        const result = await marriageContract.exchangeRate()
        dispatch(setExchangeRate(result.toNumber()))
        const nftPr = await marriageContract.minPricePolicy()
        dispatch(setNFTPrice(ethers.utils.formatEther(nftPr)))
        const txn7 = await marriageContract.claimPolicyDays()
        dispatch(setClaimPolicyDays(txn7.toNumber()))
        const txn9 = await marriageContract.promoAmount()
        dispatch(setPromoAmount(ethers.utils.formatEther(txn9)))
      },
      {
        retries: 5, // Number of retries before giving up
        factor: 2, // Exponential factor
        minTimeout: 1000, // Minimum wait time before retrying
        maxTimeout: 60000, // Maximum wait time before retrying
        randomize: true, // Randomize the wait time
      },
    )
  }

  const fetchLovBalance = async (familyStats: any) => {
    const result = await retry(
      async () => {
        const txn2 = await marriageContract.balanceOf(currentAccount)
        if (demo === false) {
          dispatch(setLovBalance(ethers.utils.formatEther(txn2)))
        } else {
          dispatch(setLovBalance(0))
        }
        const txn5 = await marriageContract.claimtimer(currentAccount, familyStats.id)
        dispatch(setClaimertimer(txn5.toNumber()))
      },
      {
        retries: 5, // Number of retries before giving up
        factor: 2, // Exponential factor
        minTimeout: 1000, // Minimum wait time before retrying
        maxTimeout: 60000, // Maximum wait time before retrying
        randomize: true, // Randomize the wait time
      },
    )
  }

  const fetchLovBalanceShort = async () => {
    const result = await retry(
      async () => {
        const txn2 = await marriageContract.balanceOf(currentAccount)
        if (demo === false) {
          dispatch(setLovBalance(ethers.utils.formatEther(txn2)))
        } else {
          dispatch(setLovBalance(0))
        }
      },
      {
        retries: 5, // Number of retries before giving up
        factor: 2, // Exponential factor
        minTimeout: 1000, // Minimum wait time before retrying
        maxTimeout: 60000, // Maximum wait time before retrying
        randomize: true, // Randomize the wait time
      },
    )
  }

  const loadFamilyData = () => {
    setIsLoaded(false)
    fetchFamilyContract()
    getEthBalance()
    fetchConstants()
    fetchAvailableAssets()
  }

  return {
    loadFamilyData,
    isLoaded,
  }
}

export default useFamilyContract
