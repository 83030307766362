import { ethers } from 'ethers'
import { CONTRACTS } from '@/configs/contracts'
import { getAlchemy } from '@/modules/useAlchemy'
import { NEXT_PUBLIC_LIVECOIN_API, ZERO_ADDR_ETH } from '@/configs'
const ABI = ['function balanceOf(address addr) view returns (uint256)']

export async function fetchMainBalanceAlchemy(mainAddress, currency, currentNetwork, Provider) {
  const alchemy = getAlchemy(currentNetwork)
  const _ethswapAddress = CONTRACTS[currentNetwork].WethAddress
  let _LOVEaddress = CONTRACTS[currentNetwork].ContractAddress

  const balances = await alchemy.core.getTokenBalances(mainAddress)

  async function fetchPrice(contractAddress: string) {
    if (currentNetwork === '0x5' || currentNetwork === '0xaa36a7') {
      return 0
    }
    if (contractAddress) {
      try {
        const price = await fetch(new Request('https://api.livecoinwatch.com/coins/contract'), {
          method: 'POST',
          headers: new Headers({
            'content-type': 'application/json',
            'x-api-key': NEXT_PUBLIC_LIVECOIN_API,
          }),
          body: JSON.stringify({
            currency: 'USD',
            platform: CONTRACTS[currentNetwork].currency,
            address: contractAddress,
            meta: false,
          }),
        })
        if (price.status === 200) {
          const FetchData = await price.json()
          return FetchData.rate
        } else {
          return 0
        }
      } catch (error: any) {
        return 0
      }
    } else return 0
  }

  const txarray = []
  const balance = await Provider.getBalance(mainAddress)
  const parsedbalance = ethers.utils.formatEther(balance)
  const ethPrice = await fetchPrice(CONTRACTS[currentNetwork].WethAddress)
  const USDvalue = Number(parsedbalance) * ethPrice
  txarray.push({
    id: 0,
    tokenName: currency,
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    tokenBalance: parsedbalance,
    tokenAddress: ZERO_ADDR_ETH,
    ercSymbol: currency,
    ercDecimals: 18,
    rate: ethPrice ? ethPrice : 0,
    USDvalue: USDvalue ? USDvalue : 0,
  })

  //let balanceJson = await balances.json();
  if (balances) {
    for (let i = 0; i < balances.tokenBalances.length; i++) {
      const contractAddress = balances.tokenBalances[i].contractAddress
      const metadata = await alchemy.core.getTokenMetadata(contractAddress)
      const decimals = metadata.decimals !== null ? metadata.decimals : 18

      const tokenBalance = Number(
        ethers.utils.formatUnits(ethers.BigNumber.from(balances.tokenBalances[i].tokenBalance), decimals),
      )
      if (tokenBalance > 0) {
        let tokenPrice = 0
        // tokenPrice = await fetchPrice(balances.tokenBalances[i].contractAddress)
        const USDvalue = Number(tokenBalance) * tokenPrice.rate
        txarray.push({
          id: i + 1,
          tokenName: metadata.name,
          logo: metadata.logo,
          tokenBalance: tokenBalance,
          tokenAddress: contractAddress,
          ercSymbol: metadata.symbol,
          ercDecimals: metadata.decimals,
          rate: tokenPrice ? tokenPrice : 0,
          USDvalue: USDvalue ? USDvalue : 0,
        })
      }
    }
  }

  const WethCheck = txarray.find((e) => e.tokenAddress.toUpperCase() === _ethswapAddress.toUpperCase())
  if (!WethCheck && _ethswapAddress !== null) {
    const balanceWETH = await alchemy.core.getTokenBalances(mainAddress, [_ethswapAddress])
    const tokenBalance = Number(
      ethers.utils.formatUnits(ethers.BigNumber.from(balanceWETH.tokenBalances[0].tokenBalance), 18),
    )
    let tokenPrice
    let USDvalue
    if (tokenBalance > 0) {
      tokenPrice = await fetchPrice(_ethswapAddress)
      USDvalue = Number(tokenBalance) * Number(tokenPrice.rate)
    }

    txarray.push({
      id: 99,
      tokenName: `Wrapped ${currency}`,
      logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
      tokenBalance: tokenBalance,
      tokenAddress: _ethswapAddress,
      ercSymbol: `W${currency}`,
      ercDecimals: 18,
      rate: tokenPrice ? tokenPrice : 0,
      USDvalue: USDvalue ? USDvalue : 0,
    })
  }

  const LoveCheck = txarray.find((e) => e.tokenAddress.toUpperCase() === _LOVEaddress.toUpperCase())
  if (!LoveCheck && _LOVEaddress !== ZERO_ADDR_ETH) {
    const balanceLOVE = await alchemy.core.getTokenBalances(mainAddress, [_LOVEaddress])
    txarray.push({
      id: 100,
      tokenName: 'LOVE tokens',
      logo: '',
      tokenBalance: ethers.utils.formatUnits(ethers.BigNumber.from(balanceLOVE?.tokenBalances[0].tokenBalance), 18),
      tokenAddress: _LOVEaddress,
      ercSymbol: 'LOVE',
      ercDecimals: 18,
      rate: 0,
      USDvalue: 0,
    })
  }

  return txarray
}
